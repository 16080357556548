
import { translations } from "@/mixins";
import GoogleMapsLoader from "google-maps";
import {
  ValidationObserver,
  configure,
  ValidationProvider,
} from "vee-validate";
import {
  Component,
  ProvideReactive,
  Prop,
  Vue,
  Watch,
  Emit,
} from "vue-property-decorator";

GoogleMapsLoader.KEY = "AIzaSyBGrCkKHoHh3nvofpx3OhbVZpkG9cOSf9Q";

@Component({
  components: {
    ValidationObserver,
  },
  mixins: [translations],
})
export default class ChangeAreaNameModal extends Vue {
  private google: any;
  private gmap: any;
  private marker: any;
  public translate!: Function;

  @Prop() open!: Boolean;
  @Prop() location!: any;
  @Watch("open", { immediate: true, deep: true })
  onOpen(newVal: Boolean) {
    if (newVal) {
      setTimeout(() => {
        GoogleMapsLoader.load((google: any) => {
          this.google = google;
          let lat = 24.774265,
            lng = 46.738586;
            console.log(this.location)
          if (this.location.lat && this.location.long) {
            lat = this.location.lat;
            lng = this.location.long;
          }
          this.initMap(lat, lng);
        });
      }, 100);
    }
  }

  @Watch("marker", { immediate: true, deep: true })
  onMarkerChange(marker: any) {
    console.log(this.marker);
  }

  get isOpen(): Boolean {
    return this.open;
  }

  set isOpen(newVal: Boolean) {
    this.$emit("modal:update", newVal);
  }

  private initMap(lat: number, lng: number, color: String = "#5e72e4") {
    console.log('map init')
    let google = this.google;
    let map = document.getElementById("map-select-coordinates");

    let myLatlng = new google.maps.LatLng(lat, lng);
    let mapOptions = {
      zoom: 12,
      scrollwheel: false,
      center: myLatlng,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      mapTypeControl: false,
      streetViewControl: false,
      style: this.styles(color),
    };

    this.gmap = new google.maps.Map(map as HTMLElement, mapOptions);

    let marker = new google.maps.Marker({
      position: myLatlng,
      map: this.gmap,
      animation: google.maps.Animation.DROP,
      title: "Locations",
    });

    this.marker = marker;
    let self = this;
    this.$emit("marker:changed", this.marker);

    this.gmap.addListener("click", (mapsMouseEvent: { latLng: any }) => {
      console.log(mapsMouseEvent.latLng);
      if (self.marker) {
        self.marker.setMap(null);
      }
      let marker = new google.maps.Marker({
        position: mapsMouseEvent.latLng,
        map: this.gmap,
        animation: google.maps.Animation.DROP,
        title: "Locations",
      });

      self.marker = marker;
      self.$emit("marker:changed", self.marker);
    });
  }

  private styles(color: String = "#5e72e4") {
    return [
      {
        featureType: "administrative",
        elementType: "labels.text.fill",
        stylers: [{ color: "#444444" }],
      },
      {
        featureType: "landscape",
        elementType: "all",
        stylers: [{ color: "#f2f2f2" }],
      },
      {
        featureType: "poi",
        elementType: "all",
        stylers: [{ visibility: "on" }],
      },
      {
        featureType: "road",
        elementType: "all",
        stylers: [{ saturation: -100 }, { lightness: 45 }],
      },
      {
        featureType: "road.highway",
        elementType: "all",
        stylers: [{ visibility: "simplified" }],
      },
      {
        featureType: "road.arterial",
        elementType: "labels.icon",
        stylers: [{ visibility: "on" }],
      },
      {
        featureType: "transit",
        elementType: "all",
        stylers: [{ visibility: "on" }],
      },
      {
        featureType: "water",
        elementType: "all",
        stylers: [{ color: color }, { visibility: "on" }],
      },
    ];
  }
}
