
import { translations, eventHandler } from "@/mixins";
import { Location, Integrations } from "@/services/SOLO";
import VueTimepicker from "vue2-timepicker";
import StoreTimes from "./StoreTimes.vue";
import Translations from "./Translations.vue";
import { ValidationObserver, configure, ValidationProvider } from "vee-validate";
import { FadeTransition, ScaleTransition } from "vue2-transitions";
import { Component, Prop, Vue, Watch, Ref } from "vue-property-decorator";
import SelectCoordinatesModal from "./SelectCoordinatesModal.vue";
import { Select, Option } from "element-ui";
import moment from "moment";
import { LoadingPanel } from "@/components";
import { Integration } from "@/models";
import {mapGetters} from "vuex";

interface ojbKey {
  [x: string]: string;
}

interface locationObject {
  id: string,
  name: ojbKey;
  status: string;
  telephone: string;
  pos: string;
  code: any;
  email: string;
  country: string;
  lat: string;
  long: string;
  "delivery-enabled": Boolean;
  "pickup-enabled": Boolean;
  "curbside-enabled": Boolean;
  "open-24-hours": Boolean;
  "is-inventory-sync-enabled": Boolean;
  "is-drive-thru-enabled": Boolean;
  "has-prompt-for-table-number": Boolean;
  "delivery-charge": string;
  logistics: any;
  "logistic-selection-type": any;
  line1: ojbKey;
  "promised-time-delta": ojbKey;
  "opening-hours": Array<any>;
  "opening-hours-deliver": Array<any>;
  "opening-hours-pickup": Array<any>;
  "image-uri": any;
  "schedule-disabled-location-hour": string;
}

@Component({
  components: {
    FadeTransition,
    ScaleTransition,
    Translations,
    VueTimepicker,
    StoreTimes,
    ValidationObserver,
    SelectCoordinatesModal,
    [Select.name]: Select,
    [Option.name]: Option,
    ValidationProvider
  },
  mixins: [translations, eventHandler],
  computed: {
    ...mapGetters({
      getConceptSettings: 'account/getConceptSettings',
      getLocale: 'app/getLocale',
    }),
  },
})
export default class LocationInfoForm extends Vue {
  @Prop() location!: any;
  @Prop() isToggled!: Boolean

  $notify: any;
  confirm!: Function;
  translate!: Function;
  getLanguages!: Function
  logistics: Array<Integration> = [];
  selectionTypes: any = [];
  getConceptSettings!: any;
  private arrayLang: Array<string> = [];
  private promised_delta: Array<string> = ["delivery", "pickup"];

  private gmapModal: Boolean = false;
  private saving: Boolean = false;
  getLocale!: any;
  cloneLocation: any;
  dynamicLogistics: any;
  imageType: any = 1;
  file: any;
  imageTypes: Array<Object> = [
    {
      value: 0,
      text: "Image URL",
    },
    {
      value: 1,
      text: "Upload Image",
    },
  ];
  public $refs!: {
    storeTimes: any
    file: any
  };
  durations: any = [];

  @Watch("location", {immediate: true, deep: true})
  onCheckLogisticSelectionType(data: any) {
    this.dynamicLogistics = [];
    if(data) {
      if(data['logistic-selection-type'] === 'multiple') {
        this.logistics.forEach((logistic: any) => {
          if (logistic.attributes['multi-select']) {
            this.dynamicLogistics.push(logistic);
          }
        });
      } else if (data['logistic-selection-type'] === 'single') {
        this.dynamicLogistics = this.logistics;
        this.location['logistics'] = this.convertToInteger(this.location['logistics'])
        console.log("singleeeee");
      } else {
        this.dynamicLogistics = this.logistics;
        this.location['logistics'] = "";
      }
      console.log("watch check logistic", data);
    }
  }
 
  mounted() {
    console.log('LOCATION', this.location);
    /* @ts-ignore */
    this.arrayLang.push(this.getConceptSettings.attributes['primary-language']);
    /* @ts-ignore */
    if (this.getConceptSettings.attributes['secondary-language']) {
      /* @ts-ignore */
      this.arrayLang.push(this.getConceptSettings.attributes['secondary-language']);
    }
    
    this.getLogisticPartners();

    this.selectionTypes = [
      {label: 'None', value: null},
      {label: 'Single', value: 'single'},
      {label: 'Multiple (BETA)', value: 'multiple'},
    ];

    this.durations = [
      {
        value: "1",
        text: "1 Hour"
      },
      {
        value: "4",
        text: "4 Hours"
      },
      {
        value: "__next_business_day__",
        text: "Next Business Date"
      },
      // {
      //   value: "custom",
      //   text: "Custom"
      // }
    ];

    console.log('durationValue', this.location);

    // setTimeout(() => {
    //   this.dynamicLogistics = [];
    //   if (this.location['logistic-selection-type'] == 'multiple') {
    //     this.logistics.forEach((logistic: any) => {
    //       if (logistic.attributes['multi-select']) {
    //         this.dynamicLogistics.push(logistic);
    //       }
    //     });
    //   } else {
    //     this.dynamicLogistics = this.logistics;
    //   }

    //   this.isLoaded = true;

    //   // change value from array to integer
    //   if (this.location['logistic-selection-type'] == 'single') {
    //     this.location['logistics'] = this.location['logistics']
    //   }
    // }, 1200)
  }
  private cancel() {
    this.resetDefault()
    this.$emit("location:cancel");
  }

  @Watch('isToggled', { immediate: true, deep: true })
  onIsToggledChanged(value: any) {
    console.log('isToggled', value)
    setTimeout(() => {
      if (this.$refs.storeTimes) {
        this.$refs.storeTimes.toggle = value
        this.$refs.storeTimes.hasStoreTimes = value
      }

      if (this.location['image-uri']) {
        this.imageType = 0;
      } else {
        this.imageType = 1;
      }
    }, 500)
  }

  convertToInteger(e: any) {
    return parseInt(e);
  }

  checkSize(text: any) {

    if(this.getLocale === 'en_US') {
        if(text === 'The image field size must be less than 2000KB') {
          return 'The image file size must be less than 2MB'
        }
    }
    if(text === 'The image field size must be less than 2000KB') {
          return 'يجب أن يكون حجم ملف الصورة أقل من 2 ميغا بايت'
    }

  }

  async resetSelection(type: any) {
    this.dynamicLogistics = [];
    if (type == 'single') {
      this.location['logistics'] = null;
      this.dynamicLogistics = this.logistics;
    } else if (this.location['logistic-selection-type'] == 'multiple') {
      // emptying the logistic-selection-type 
      // so the values will restart (temporary)
      this.location['logistic-selection-type'] = ''
      
      this.location['logistics'] = [];
      await this.logistics.forEach((logistic: any) => {
        if (logistic.attributes['multi-select']) {
          this.dynamicLogistics.push(logistic);
        }
      });

      // apply delay to replace temporary logistic-selection-type
      // setTimeout(() => {
        this.location['logistic-selection-type'] = 'multiple'
      // }, 100)
    } else {
      this.location['logistics'] = [];
      this.dynamicLogistics = this.logistics;
    }
  }

  private deleteLocation() {
    this.confirm(
      this.$bvModal,
      this.translate("Are you sure you want to delete this item?")
    ).then((value: boolean) => {
      if (value) {
        Location.removeLocation(this.location.id).then((response: any) => {
          this.$notify({
              title: this.translate("DATA DELETED"),
              verticalAlign: "bottom",
              horizontalAlign: "left",
              message: this.translate("Location successfully deleted"),
              type: "success",
              icon: "fas fa-trash",
            });
            this.$emit('location:deleted', this.location)
        });
      }
    });
  }


  private getLogisticPartners() {
    Integrations.getIntegration('food-logistics').then((response) => {
      this.logistics = response.data.data
      console.log("logisssticsss", this.logistics);
    });
  }

  private selectCoordinates(coordinates: any) {
    this.location.lat = coordinates.position.lat();
    this.location.long = coordinates.position.lng();
  }

  private openGmapModal(open: Boolean = true) {
    this.gmapModal = open;
  }

  setDefaultHours(toggle: Boolean = false, hasStoreTimes: Boolean = false) {
    if (toggle) {
      if (!hasStoreTimes) {
        this.location['opening-hours-deliver'] = JSON.parse(this.getConceptSettings.attributes['default-opening-hours']);
        this.location['opening-hours-pickup'] = JSON.parse(this.getConceptSettings.attributes['default-opening-hours']);
      }
    } else {
      if (hasStoreTimes) {
        this.location['opening-hours'] = JSON.parse(this.getConceptSettings.attributes['default-opening-hours']);
      }
    }
  }

  private submit(e: any) {
    console.log({location: this.location});
    // We need to get the default timezone of the concept in order for us
    // convert the value to UTC and submit to the API
    const timezone = this.getConceptSettings.attributes['default-timezone'] || 'GMT+3';
    const tz = `Etc/${timezone}`;
    const isEdit = !!this.location.id;

    if (this.location['logistic-selection-type'] == 'single') {
      this.location['logistics'] = [this.location['logistics']];
      // if (this.location['logistics']) {
      //   this.location['logistics'] = [this.location['logistics']].filter(function(val) { return val !== null })
      // } else {
      //   this.location['logistics'] = []
      // }
    }

    let locationClone = { ...this.location };
    if (this.saving) {
      return;
    }

    locationClone["opening-hours"] = locationClone["opening-hours"].map(
      (item: any) => {
        return {
          day: item.day,
          open: this.checkStoreTime(moment(`${item.open}`, "hh:mm A").format("HH:mm")),
          closed:this.checkStoreTime(moment(`${item.closed}`, "hh:mm A").format("HH:mm"))
        };
      }
    );
    if (this.$refs.storeTimes.toggle) {
      locationClone["opening-hours-deliver"] = locationClone["opening-hours-deliver"].map(
        (item: any) => {
          return {
            day: item.day,
            open: this.checkStoreTime(moment.utc(`${item.open}`, "hh:mm A").tz(tz).format("HH:mm")),
            closed:this.checkStoreTime(moment.utc(`${item.closed}`, "hh:mm A").tz(tz).format("HH:mm")),
          };
        }
      );
      locationClone["opening-hours-pickup"] = locationClone["opening-hours-pickup"].map(
        (item: any) => {
          return {
            day: item.day,
            open: this.checkStoreTime(moment.utc(`${item.open}`, "hh:mm A").tz(tz).format("HH:mm")),
            closed:this.checkStoreTime(moment.utc(`${item.closed}`, "hh:mm A").tz(tz).format("HH:mm")),
          };
        }
      );
      // Temporary fix only for edit
      // create API still has an issue when setting
      // opening-hours to null or empty array.
      // if (isEdit) {
      //   locationClone["opening-hours"] = null;
      // }
      locationClone["opening-hours"] = null;
    } else {
      locationClone["opening-hours-deliver"] = null;
      locationClone["opening-hours-pickup"] = null;
    }
    if (isEdit) {
      this.update(locationClone);
    } else {
      this.create(locationClone);
    }
  }

  private checkStoreTime(storeTime: any) {
    var isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(storeTime);
    if(isValid) {
      return storeTime
    }
    return null
  }

   resetDefault() {
    this.location.name[this.getConceptSettings.attributes['primary-language']] = '';
    this.location.name[this.getConceptSettings.attributes['secondary-language']] = '';
    this.location.line1[this.getConceptSettings.attributes['primary-language']] = '';
    this.location.line1[this.getConceptSettings.attributes['secondary-language']] = '';
    this.location.email = '';
    this.location.country = '';
    this.location.lat = '';
    this.location.long = '';
    this.location.telephone = '';
  }

  private create(payload: any) {
    if (!this.imageType) {
      this.saving = true;
      payload['image-uri'] = payload['image-uri'] == '' ? '__no_feedback_image__' : payload['image-uri'];
      Location.newLocation(payload)
        .then((response: any) => {
          this.resetDefault();
          this.saving = false;
          this.$emit("location:created", response.data.data);
        })
        .catch((err: any) => {
          let errMsg: any = []

          for (let error in err.response.data.error) {
            errMsg = err.response.data.error[error]
          }

          this.$notify({
            title: "SYSTEM ERROR!",
            verticalAlign: "bottom",
            horizontalAlign: "left",
            message: errMsg.detail,
            type: "danger",
            icon: "fas fa-bomb",
          })

          this.saving = false
        });
    } else {

    let formData = new FormData();
    const timezone = this.getConceptSettings.attributes['default-timezone'] || 'GMT+3';
    const tz = `Etc/${timezone}`;

    for (let i in this.arrayLang) {
      formData.append(
          `name[${this.arrayLang[i]}]`,
          this.location.name[this.arrayLang[i]] ?? ''
      );

      formData.append(
          `line1[${this.arrayLang[i]}]`,
          this.location.line1[this.arrayLang[i]] ?? ''
      );

    }

    for(let i in this.promised_delta) {
      formData.append(
          `promised-time-delta[${this.promised_delta[i]}]`,
          this.location['promised-time-delta'][this.promised_delta[i]] ?? ''
      );
    }

    // formData.append("opening-hours", JSON.stringify(payload['opening-hours']));

    if (this.$refs.storeTimes.toggle) {

      formData.append("opening-hours-deliver", JSON.stringify(payload['opening-hours-deliver']));

      formData.append("opening-hours-pickup", JSON.stringify(payload['opening-hours-pickup']));

      // Temporary fix only for edit
      // create API still has an issue when setting
      // opening-hours to null or empty array.
      // if (isEdit) {
      //   locationClone["opening-hours"] = null;
      // }
      formData.append("opening-hours", "");

    } else {
      formData.append("opening-hours", JSON.stringify(payload['opening-hours']));
      formData.append("opening-hours-deliver", "");
      formData.append("opening-hours-pickup", "");

    }

    formData.append("status", this.location['status']);
    formData.append("telephone", this.location['telephone']);
    formData.append("pos", this.location['pos']);
    formData.append("code", this.location['code']);
    formData.append("email", this.location['email'] ?? "");
    formData.append("country", this.location['country']);
    formData.append("lat", this.location['lat']);
    formData.append("long", this.location['long']);
    // formData.append("delivery-enabled", this.location['delivery-enabled']);
    // formData.append("pickup-enabled", this.location['pickup-enabled']);
    // formData.append("curbside-enabled", this.location['curbside-enabled']);
    // formData.append("open-24-hours", this.location['open-24-hours']);
    // formData.append("is-inventory-sync-enabled", this.location['is-inventory-sync-enabled']);
    // formData.append("is-drive-thru-enabled", this.location['is-drive-thru-enabled']);
    /* @ts-ignore */
    formData.append("delivery-enabled", this.location['delivery-enabled'] ? 1 : 0);
    /* @ts-ignore */
    formData.append("pickup-enabled", this.location['pickup-enabled'] ? 1 : 0);
    /* @ts-ignore */
    formData.append("curbside-enabled", this.location['curbside-enabled'] ? 1 : 0);
    /* @ts-ignore */
    formData.append("open-24-hours", this.location['open-24-hours'] ? 1 : 0);
    /* @ts-ignore */
    formData.append("is-inventory-sync-enabled", this.location['is-inventory-sync-enabled'] ? 1 : 0);
    /* @ts-ignore */
    formData.append("is-drive-thru-enabled", this.location['is-drive-thru-enabled'] ? 1 : 0);
    /* @ts-ignore */
    formData.append("has-prompt-for-table-number", this.location['has-prompt-for-table-number'] ? 1 : 0);
    formData.append("delivery-charge", this.location['delivery-charge']);
    formData.append("delivery-charge-per-km", this.location['delivery-charge-per-km']);
    formData.append("delivery-maximum-distance", this.location['delivery-maximum-distance']);
    formData.append("logistic-selection-type", this.location['logistic-selection-type'] ?? '');
    if (this.$refs.file.files[0]) {
      formData.append('image', this.$refs.file.files[0]);
    } else {
      if (!this.location['image-uri']) {
        formData.append('image-uri', '__no_feedback_image__');
      }
    }


     if (this.location['logistic-selection-type'] == 'single') {
        this.location['logistics'] = [this.location['logistics']].filter(function(val) { return val !== null })
        formData.append("logistics[0]", this.location['logistics']);
      } else {
        if (this.location['logistics'].length) {
          this.location['logistics'].forEach((logistic: any, index: any) => {
            formData.append(`logistics[${index}]`, logistic);
          });
        }
      }

          this.saving = true;
          Location.newLocationFormData(formData)
            .then((response: any) => {
              this.resetDefault();
              this.saving = false;
              this.$emit("location:created", response.data.data);
            })
            .catch((err: any) => {
              let errMsg: any = []

              for (let error in err.response.data.error) {
                errMsg = err.response.data.error[error]
              }

              this.$notify({
                title: "SYSTEM ERROR!",
                verticalAlign: "bottom",
                horizontalAlign: "left",
                message: errMsg.detail,
                type: "danger",
                icon: "fas fa-bomb",
              })

              this.saving = false
            });
      }

  }

  private update(payload: any) {
    if (!this.imageType) {
      this.saving = true;
      payload['image-uri'] = payload['image-uri'] == '' ? '__no_feedback_image__' : payload['image-uri'];
      Location.updateLocation(payload, payload.id)
        .then((response: any) => {
          /* @ts-ignore */
          analytics.track('edit_location', {
            location_id: payload.id,
          });
          this.saving = false;
          this.$notify({
            title: this.translate("RECORDS SAVED!"),
            verticalAlign: "bottom",
            horizontalAlign: "left",
            message: this.translate("Record have been saved successfully"),
            type: "success",
            icon: "fas fa-check",
          });
          this.$emit("location:updated", response.data.data);
          this.$emit("location:cancel");

        })
        .catch((err) => {
          let errMsg: any = []

          for (let error in err.response.data.error) {
            errMsg = err.response.data.error[error]
          }

          this.$notify({
            title: "SYSTEM ERROR!",
            verticalAlign: "bottom",
            horizontalAlign: "left",
            message: errMsg.detail,
            type: "danger",
            icon: "fas fa-bomb",
          })

          this.saving = false;

        });
    } else {

    let formData = new FormData();
    const timezone = this.getConceptSettings.attributes['default-timezone'] || 'GMT+3';
    const tz = `Etc/${timezone}`;

    for (let i in this.arrayLang) {
      formData.append(
          `name[${this.arrayLang[i]}]`,
          payload.name[this.arrayLang[i]] ?? ''
      );

      formData.append(
          `line1[${this.arrayLang[i]}]`,
          payload.line1[this.arrayLang[i]] ?? ''
      );
    }
    for(let i in this.promised_delta) {
      formData.append(
        `promised-time-delta[${this.promised_delta[i]}]`,
        this.location['promised-time-delta'][this.promised_delta[i]] ?? ''
      );
    }

    // formData.append("opening-hours", JSON.stringify(payload['opening-hours']));

    if (this.$refs.storeTimes.toggle) {

      formData.append("opening-hours-deliver", JSON.stringify(payload['opening-hours-deliver']));

      formData.append("opening-hours-pickup", JSON.stringify(payload['opening-hours-pickup']));

      // Temporary fix only for edit
      // create API still has an issue when setting
      // opening-hours to null or empty array.
      // if (isEdit) {
      //   locationClone["opening-hours"] = null;
      // }
      formData.append("opening-hours", "");

    } else {
      formData.append("opening-hours", JSON.stringify(payload['opening-hours']));
      formData.append("opening-hours-deliver", "");
      formData.append("opening-hours-pickup", "");

    }

    formData.append("status", this.location['status']);
    formData.append("telephone", this.location['telephone']);
    formData.append("pos", this.location['pos']);
    formData.append("code", this.location['code']);
    formData.append("email", this.location['email'] ?? "");
    formData.append("country", this.location['country']);
    formData.append("lat", this.location['lat']);
    formData.append("long", this.location['long']);
    // formData.append("delivery-enabled", this.location['delivery-enabled']);
    // formData.append("pickup-enabled", this.location['pickup-enabled']);
    // formData.append("curbside-enabled", this.location['curbside-enabled']);
    // formData.append("open-24-hours", this.location['open-24-hours']);
    // formData.append("is-inventory-sync-enabled", this.location['is-inventory-sync-enabled']);
    // formData.append("is-drive-thru-enabled", this.location['is-drive-thru-enabled']);
    /* @ts-ignore */
    formData.append("delivery-enabled", this.location['delivery-enabled'] ? 1 : 0);
    /* @ts-ignore */
    formData.append("pickup-enabled", this.location['pickup-enabled'] ? 1 : 0);
    /* @ts-ignore */
    formData.append("curbside-enabled", this.location['curbside-enabled'] ? 1 : 0);
    /* @ts-ignore */
    formData.append("open-24-hours", this.location['open-24-hours'] ? 1 : 0);
    /* @ts-ignore */
    formData.append("is-inventory-sync-enabled", this.location['is-inventory-sync-enabled'] ? 1 : 0);
    /* @ts-ignore */
    formData.append("is-drive-thru-enabled", this.location['is-drive-thru-enabled'] ? 1 : 0);/* @ts-ignore */
    formData.append("has-prompt-for-table-number", this.location['has-prompt-for-table-number'] ? 1 : 0);
    formData.append("delivery-charge", this.location['delivery-charge']);
    formData.append("delivery-charge-per-km", this.location['delivery-charge-per-km']);
    formData.append("delivery-maximum-distance", this.location['delivery-maximum-distance']);
    formData.append("logistic-selection-type", this.location['logistic-selection-type'] ?? '');
    if (this.$refs.file.files[0]) {
      formData.append('image', this.$refs.file.files[0]);
    } else {
      if (!this.location['image-uri']) {
        formData.append('image-uri', '__no_feedback_image__');
      }
    }
    formData.append('_method', 'PATCH')

     if (this.location['logistic-selection-type'] == 'single') {
        this.location['logistics'] = [this.location['logistics']].filter(function(val) { return val !== null })
        formData.append("logistics[0]", this.location['logistics']);
      } else {
        if (this.location['logistics'].length) {
          this.location['logistics'].forEach((logistic: any, index: any) => {
            formData.append(`logistics[${index}]`, logistic);
          });
        }
      }

       this.saving = true;
        Location.updateLocationFormData(formData, payload.id)
          .then((response: any) => {
            /* @ts-ignore */
            analytics.track('edit_location', {
              location_id: payload.id,
            });
            this.saving = false;
            this.$notify({
              title: this.translate("RECORDS SAVED!"),
              verticalAlign: "bottom",
              horizontalAlign: "left",
              message: this.translate("Record have been updated successfully"),
              type: "success",
              icon: "fas fa-check",
            });
            this.$emit("location:updated", response.data.data);
            this.$emit("location:cancel");

          })
          .catch((err) => {
            let errMsg: any = []

            for (let error in err.response.data.error) {
              errMsg = err.response.data.error[error]
            }

            this.$notify({
              title: "SYSTEM ERROR!",
              verticalAlign: "bottom",
              horizontalAlign: "left",
              message: errMsg.detail,
              type: "danger",
              icon: "fas fa-bomb",
            })

            this.saving = false;

          });
    }

  }
}
